<div class="form-container">
    <form [formGroup]="contactForm" (ngSubmit)="enviar()">
        <div>
            <h5 class="title">{{ titulo }}</h5>
        </div>
        <div>
            <mat-form-field appearance="outline" floatLabel="always" class="fz-18">
                <mat-label>Nombre Completo</mat-label>
                <input
                    matInput
                    formControlName="nombre_completo"
                    [disabled]="formSubmitted || enviando"
                />
                @if (formSubmitted && f['nombre_completo'].errors) {
                    <mat-error> El nombre completo es obligatorio. </mat-error>
                }
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" floatLabel="always" class="fz-18">
                <mat-label>Correo Electrónico</mat-label>
                <input
                    matInput
                    formControlName="email"
                    type="email"
                    [disabled]="formSubmitted || enviando"
                />
                @if (formSubmitted && f['email'].errors) {
                    <mat-error>
                        El correo electrónico es obligatorio.
                    </mat-error>
                }
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="fz-18">
                <mat-label>Teléfono</mat-label>
                <input
                    matInput
                    formControlName="telefono"
                    [disabled]="formSubmitted || enviando"
                />
                @if (formSubmitted && f['telefono'].errors) {
                    <mat-error> El teléfono es obligatorio. </mat-error>
                }
            </mat-form-field>

            @if (cargarCv == false) {
                <mat-form-field appearance="outline" floatLabel="always" class="fz-18">
                    <mat-label>Empresa</mat-label>
                    <input
                        matInput
                        formControlName="empresa"
                        [disabled]="formSubmitted || enviando"
                    />
                    @if (formSubmitted && f['empresa'].errors) {
                        <mat-error> La empresa es obligatoria. </mat-error>
                    }
                </mat-form-field>
            }
            @if (cargarCv == true) {
                <button
                    type="button"
                    class="cargar-cv-button"
                    mat-flat-button
                    (click)="fileInput.click()"
                    [disabled]="formSubmitted || enviando"
                >
                    Cargar CV
                </button>
                <input
                    hidden
                    (change)="onFileSelected($event)"
                    #fileInput
                    type="file"
                    name="cv"
                />
            }
        </div>
        <div>
            <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="text-area-formField fz-18"
                
            >
                <mat-label>Mensaje</mat-label>
                <textarea
                    matInput
                    formControlName="mensaje"
                    placeholder="¿En qué rubro estás y cómo podemos ayudarte?"
                    [disabled]="formSubmitted || enviando"
                ></textarea>
                @if (formSubmitted && f['mensaje'].errors) {
                    <mat-error> El mensaje es obligatorio. </mat-error>
                }
            </mat-form-field>
        </div>
        <div>
            <button type="submit" [disabled]="!contactForm.valid || formSubmitted || enviando" (click)="enviar()">
                @if (!formSubmitted || !enviando) {
                    <img src="img/send.svg" />
                }
                {{formSubmitted ? "Hemos recibido tu mensaje correctamente" :  "ENVIAR"}}
            </button>
        </div>
    </form>
</div>
