import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-carrousel-item',
    standalone: true,
    imports: [],
    templateUrl: './seccion-cinco-carrusel-item.component.html',
    styleUrl: './seccion-cinco-carrusel-item.component.scss',
})
export class SeccionCincoCarruselItem {
    @Input({ required: true }) carrouselItemTitle!: string;
    @Input({ required: true }) carrouselItemImgURL!: string;
    @Input({ required: true }) carrouselItemText!: {
        textID: number;
        text: string;
    }[];
}
