<div class="caroulse-item-container">
    <div class="img-contianer">
        <img [src]="carrouselItemImgURL" />
    </div>
    <div class="content-container">
        <span class="title">
            {{ carrouselItemTitle }}
        </span>
        <div class="content">
            @for (item of carrouselItemText; track item.textID) {
                <p>
                    {{ item.text }}
                </p>
            }
        </div>
    </div>
</div>
