import { stringify } from 'qs';
import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { QsSerializer } from './qs.serializer';
import { environment } from '../../environments/environment.development';

@Injectable({ providedIn: 'root' })
export class ApiService {
    public mostrarMensajes: boolean = true;

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {}

    observeResponse(options?: any) {
        return {
            observe: 'response',
            ...options,
        };
    }

    private generarParametrosUrl(uri: string, params: any = {}): string {
        let url = environment.API_ENDPOINT + uri;
        let strParams = stringify(params);
        if (strParams) {
            url += '?' + strParams;
        }

        return url;
    }

    public get(uri: string, params: any = {}): Observable<any> {
        let url = this.generarParametrosUrl(uri, params);

        return this.handle(
            this.http.get(url, {
                observe: 'body',
            }),
        );
    }

    public async getData(
        uri: string,
        params: any = {},
        parsed: boolean = true,
    ): Promise<any> {
        let response = await this.get(uri, params).toPromise();

        let delayed = await this.manageDelayedCalls(
            parsed ? response.data : response,
            parsed,
        );

        return delayed.data ?? delayed;
        //return parsed ? response.data : response;
    }

    public async getRaw(uri: string, params: any = {}): Promise<any> {
        let response = await this.get(uri, params).toPromise();

        if (response.data.askLaterFor) {
            let delayed = await this.manageDelayedCalls(response.data);
            return delayed;
        }

        return response;
    }

    public getRawData(uri: string, responseType: any, params: any = {}) {
        let url = this.generarParametrosUrl(uri, params);

        return this.handle(
            this.http.get(url, {
                responseType: responseType,
            }),
        ).toPromise();
    }

    public getAll(uri: string, params: any = {}): Promise<any> {
        params['limit'] = 0;
        return this.get(uri, params).toPromise();
    }

    public getAllData(uri: string, params: any = {}): Promise<any> {
        params['limit'] = 0;
        return this.getData(uri, params);
    }

    public async post(uri: string, body: any, options?: any): Promise<any> {
        console.log(uri, body);
        let requestOptions = {
            headers: new HttpHeaders({
                enctype: 'multipart/form-data', // Ensure multipart form data is sent
            }),
            ...options,
        };
        let response = await this.handle(
            this.http.post(
                environment.API_ENDPOINT + uri,
                this.getEncodedBody(body),
                requestOptions,
            ),
        ).toPromise();

        // // @ts-ignore;
        // if (response.data.askLaterFor) {
        //     // @ts-ignore;
        //     let delayed = await this.manageDelayedCalls(response.data);
        //     return delayed;
        // }

        return response;
    }

    public put(uri: string, body: any, options?: any): Promise<any> {
        return this.handle(
            this.http.put(
                environment.API_ENDPOINT + uri,
                this.getEncodedBody(body),
                options,
            ),
        ).toPromise();
    }

    public patch(uri: string, body: any, options?: any) {
        return this.handle(
            this.http.patch(
                environment.API_ENDPOINT + uri,
                this.getEncodedBody(body),
                options,
            ),
        );
    }

    public delete(uri: string): Promise<any> {
        return this.handle(
            this.http.delete(environment.API_ENDPOINT + uri),
        ).toPromise();
    }

    private async manageDelayedCalls(response: any, parsed: boolean = true) {
        if (!response.askLaterFor) {
            return response;
        }

        let taskId = response.askLaterFor;

        while (true) {
            let response = await this.getRaw(
                `/base/delayed-task/${taskId}/status`,
            );
            if (response.data.status === 'pending') {
                await this.wait(5000);
                continue;
            }

            if (response.data.status === 'finished') {
                return response.data.data;
            }

            return;
        }
    }

    private wait(ms: number): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    }

    private getEncodedBody(data: any): any {
        return new QsSerializer()
            .serialize(data)
            .filter((v) => v.value instanceof File).length > 0
            ? this.getEncodedBodyWithFile(data)
            : data;
    }

    private getEncodedBodyWithFile(data: any): FormData {
        let body = new FormData();
        new QsSerializer().serialize(data).forEach((item) => {
            body.append(item.name, item.value);
        });

        return body;
    }

    private handle(o: Observable<any>): Observable<ArrayBuffer> {
        return o.pipe(
            catchError((e: HttpErrorResponse) => {
                console.log('catchError e', e);
                console.log(
                    'e.status',
                    e.status,
                    'this.mostrarMensajes',
                    this.mostrarMensajes,
                );
                if (
                    [403, 404, 422].includes(e.status) &&
                    this.mostrarMensajes
                ) {
                    this.getErrorInfo(e.error).then((error) => {
                        // this.snackBar.show(error.message || error.error);
                    });
                }
                if (e.status === 401) {
                    this.router.navigateByUrl('/auth/login');
                    window.scroll(0, 0);
                }
                return throwError(e);
            }),
        );
    }

    private async getErrorInfo(error: any) {
        if (error.constructor.name === 'Blob') {
            return JSON.parse(await (error as Blob).text());
        }

        return error;
    }
}
