import {
    afterNextRender,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { SeccionCincoCarruselItem } from '../seccion-cinco-carrusel-item/seccion-cinco-carrusel-item.component';

@Component({
    selector: 'app-carousel',
    standalone: true,
    imports: [SeccionCincoCarruselItem],
    templateUrl: './seccion-cinco-carrusel.component.html',
    styleUrl: './seccion-cinco-carrusel.component.scss',
})
export class SeccionCincoCarrusel implements OnInit, OnDestroy {
    @Input() items: {
        carrouselItemID: number;
        carrouselItemTitle: string;
        carrouselItemImgURL: string;
        carrouselItemText: { textID: number; text: string }[];
    }[] = [];
    @Input() interval = 10000;
    activeIndex = 0;
    private timer: string | number | NodeJS.Timeout | undefined;

    constructor(private cdr: ChangeDetectorRef) {
        afterNextRender(() => {
            this.startAutoSlide();
        });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        clearInterval(this.timer);
    }

    startAutoSlide() {
        this.timer = setInterval(() => {
            this.next();
        }, this.interval);
    }

    next() {
        this.activeIndex = (this.activeIndex) % this.items.length;
        this.cdr.markForCheck(); // Forzar detección de cambios
    }

    goTo(index: number) {
        this.activeIndex = index;
        clearInterval(this.timer);
        this.startAutoSlide();
    }
}
