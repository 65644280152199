<div class="carousel">
    <div class="carousel-items">
        @for (item of items; track item.carrouselItemID) {
            <div [hidden]="item.carrouselItemID !== activeIndex">
                <app-carrousel-item
                    [carrouselItemTitle]="item.carrouselItemTitle"
                    [carrouselItemImgURL]="item.carrouselItemImgURL"
                    [carrouselItemText]="item.carrouselItemText"
                ></app-carrousel-item>
            </div>
        } @empty {}
    </div>
    <div class="dots">
        @for (item of items; track item.carrouselItemID) {
            <span
                class="dot"
                [class.active]="item.carrouselItemID === activeIndex"
                (click)="goTo(item.carrouselItemID)"
                tabindex="i"
            ></span>
        } @empty {}
    </div>
</div>
